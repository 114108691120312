.display {
  background-color: white;
  border: 1px solid rgba(184, 182, 182, 0.616);
  box-shadow: 10px 15px 25px rgb(29, 28, 28);
  width: 440px;
  height: 600px;
  margin: auto;
  padding: 30px 30px 60px 30px;
  padding-top: 10px;
  z-index: 10;
  animation-duration: 1s;
  animation-name: slideInFromRight;
  animation-iteration-count: 1;
}

.WorkHistory-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
}

.display h2,
.display h3,
.display p {
  color: BLACK;
  margin: 5px 0 0 0;
  font-size: 1em;
  font-weight: 500 ;
}

.download {
  margin-top: 15px;
}

.display p {
  font-size: 12px;
}
.contact-display {
  display: flex;
  flex-direction: row;
  font-family: 'Times New Roman', Times, serif;
}
.contact-display div {
  margin-top: 20px;
  flex-direction: column;
}

.contact-display p {
  font-size: 0.7em;
  margin: 0;
}
.contact-display h1 {
  font-size: 2em;
  margin-top: 8px;
  padding-right: 10px;
}

.institution {
  display: flex;
  flex-direction: column;
  margin: 10px 0 0 15px;
}

.edex-title {
  display: flex;
  flex-direction: row;
}
.edex-title p {
  justify-self: right;
  margin-left: auto;
  padding: 5px;
}
.edex-title h3 {
  margin-right: auto;
}

.display ul {
  font-size: 0.8rem;
  padding: 0;
  margin: 0;
  font-weight: 300;
}
object {
    width:750px;
    height: 1025px;
    z-index: 10;
    box-shadow: 10px 15px 25px rgb(29, 28, 28);
    animation-duration: 1s;
    animation-name: slideInFromRight;
    animation-iteration-count: 1;

}


@media only screen and (max-width: 750px) {
object{
    width:370px;
    height: 525px;
    z-index: 100;


}
  .institution {
    margin: 5px 0 0 4px;
  }

  .contact-display h1 {
    font-size:3em;
  }
  .display {
    width: 305px;
    height: 550px;
  }

.display h2,
.display h3 {

  font-size: .8em;
}
.display li {
    font-size: .85em;
  }
  .display h1 {
      font-size: 1.4em;
  }
}

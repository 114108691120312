.particle-container {
  position: absolute;
  height: 0;
  top: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100vw;
  height: 100%;
  z-index: -1;
}
.particle {
  border-radius: 4px;
  background-color: rgb(231, 222, 222);
  position: fixed;
  left: 50vw;
  top: 50%;
  z-index: 1;
  transition: transform 2s;
}
@media only screen and (max-width: 750px) {
  .particle {
    transition: none;
  }
}

.content {
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  padding-bottom: 50px;
  animation-name: enterFromBottom;
  animation-duration: 2s;
}

.header {
  width: 100vw;
  height: calc(100vh - 150px);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  justify-content: center;
  opacity: 1;
  transition-duration: 2s;
}
.header-hidden {
  height: 0 !important;
  opacity: 0;
  margin-bottom: 0;
}

.title {
  padding: 25px;
  text-align: center;
  z-index: 2;
}

.title h2 {
  color: rgb(240, 240, 240);
  font-weight: 100;
}

@media only screen and (max-width: 768px) {
  .header {
    height: calc(100vh - 250px);
  }
}
